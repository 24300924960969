<template>
  <div class="download-card">
    <!-- Image Section -->
    <div class="image-container">
      <!-- Store Icons Positioned Above -->
    

      <!-- Download Image -->
      <img
        src="@/assets/thingsToDo/appdownloadimg.png"
        class="download-img"
        alt="App Download"
      />
      <div class="store-icons">
        
      <img
          src="@/assets/thingsToDo/gpay.png"
          class="store-icon gpay-icon"
          alt="GPay"
          @click="openGpayStore"
        />
        <img
          src="@/assets/thingsToDo/appstore.png"
          class="store-icon appstore-icon"
          alt="App Store"
          @click="openAppleStore"
        />
      </div>

    </div>

    <!-- Donation Card -->
    <div class="donation-card">
      <!-- Register Button -->
      <!-- <div class="button-container" v-if="!isLoggedIn">
        <button class="register-btn" @click="registerStudentHandler">
          {{ $t("register_a_student") }}
        </button>
      </div> -->

      <!-- Heading and GIF -->
      <div v-if="getDonationDetail.gif_message" class="card-heading">
        <p>{{ getDonationDetail.gif_message }}</p>
        <img :src="getDonationDetail.gif_img" class="gif-image" alt="gif" />
      </div>

      <!-- Donation Progress Section -->
      <div class="funding-details">
        <!-- Fund Raised Text Right Above the Progress Bar -->
        <p class="fund-raised">
          ${{ getDonationDetail.fundRaised.toLocaleString("en-US") }}
          <span v-if="getDonationDetail.Goal" class="goal-text">
            raised of ${{ getDonationDetail.Goal.toLocaleString("en-US") }} goal
          </span>
        </p>

        <!-- Progress Bar -->
        <div class="progress-bar">
          <div
            class="progress"
            :style="{ width: getDonationDetail.Percentage + '%' }"
          ></div>
          
        </div>
        <p class="progress-text">{{ getDonationDetail.Percentage }}%</p>
      </div>

      <!-- Donor Details Below the Progress Bar with Space -->
      <div class="donor-details">
        <p>
          {{ getDonationDetail.Donors.toLocaleString("en-US") }}
          <span>Donors</span>
        </p>
        <p>{{ getDonationDetail.daysLeft }} <span>days left</span></p>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="action-buttons">
      <button class="donate-btn" @click="donateNowHandler">
        <span class="icon">🤍</span> {{ $t("donate_now") }}
      </button>

      <button
        v-if="isLoggedIn"
        class="share-btn"
        @click="toggleInviteViaMailModal({ show: true })"
      >
        📧 {{ $t("share_via_email") }}
      </button>

      <button
        v-if="isLoggedIn"
        class="share-btn"
        @click="toggleInviteViaSmsModal({ show: true })"
      >
        <img src="@/assets/thingsToDo/textsms1.png" class="sms-icon" />
        {{ $t("share_via_text") }}
      </button>
      <button
        v-if="isLoggedIn"
        class="share-btn"
        @click="toggleShareFundraiserModal({ show: true }), submitForm()"
      >
        <v-icon size="30" color="white" left>mdi-share-variant-outline</v-icon>
        {{ $t("social_media_share") }}
      </button>
    </div>
  </div>
</template>

<style scoped>
/* Main Card Container */
.download-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

/* Image Section */
.image-container {
  position: relative;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

/* Download Image */
.download-img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
}
.button-container {
  width: 21rem;
}

/* Store Icons Overlaid on Download Image */
.store-icons {
  position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-55%);
    display: flex;
    gap: 20px;
}

/* Store Icon Styling */
.store-icon {
  width: 50%;
  height: auto;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.store-icon:hover {
  transform: scale(1.1);
}
.donation-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 500px;
  border-top: 12px solid #512e96;
  border-radius: 10px;
  padding: 10px;
  /* border-radius: 8px; */
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.card-heading p {
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  font-family: Roboto Slab;
  color: #512e96;
}
.gif-image {
  width: 100%;
  border-radius: 8px;
}

/* Funding Details */
.funding-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  /* margin-bottom: 10px; Add spacing below fund details */
}

.fund-raised {
  font-size: 24px;
  font-weight: bold;
  color: #067605;
  margin-bottom: 0px;
}
.goal-text {
  font-size: 16px;
  color: #757575;
}
.progress-bar {
  width: 100%;
  max-width: 400px;
  height: 12px;
  background: #ddd;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 5px; /* Add spacing above the progress bar */
}

.progress {
  background: #4a3290;
  height: 100%;
  width: 0%; /* Start with 0%, dynamically updated */
  border-radius: 20px;
  transition: width 0.5s ease-in-out;
}

.donor-details {
  display: flex;
  justify-content: space-between; /* Spaced apart */
  width: 100%;
  font-size: 16px;
}
.donor-details span {
  font-size: 14px;
  color: #757575;
}
/* Register Button */
.register-btn {
  background-color: #cc0000;
  color: white;
  height: auto;
  max-width: 21rem;
  min-width: 70%;
  font-size: 18px;
  font-weight: 600;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
/* Buttons */
.action-buttons {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 12px;
  width: 21rem;
}
.donate-btn {
  background-color: #38a737;
  color: white;
  height: auto;
  max-width: 21rem;
  min-width: 70%;
  font-size: 18px;
  font-weight: 600;
  padding: 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
.share-btn {
  background-color: #7253cf;
  color: white;
  font-size: 16px;
  padding: 10px;
  width: full;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}
.sms-icon {
  width: 24px;
  height: 24px;
  margin-right: 6px;
}
.progress-text {
  font-weight: 800;
  padding: 0px;
  font-size: 20px;
}


</style>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import Axios from "@/api/BaseAxios";
import { INVITE_VIA_SOCIAL_SHARE_DETAIL } from "@/constants/APIUrls";

export default {
  name: "DonationInfoCard",
  data() {
    return {
      fund_raise_percentage: 50,
      routerURL: ROUTER_URL,
    };
  },
  methods: {
    ...mapActions({
      showToast: "snackBar/showToast",
      toggleMakeDonationModal: "publicDonationPage/toggleMakeDonationModal",
      toggleCreateStudentModal: "publicDonationPage/toggleCreateStudentModal",
      toggleShareFundraiserModal:
        "publicDonationPage/toggleShareFundraiserModal",
      toggleParentSignUpModal: "publicDonationPage/toggleParentSignUpModal",
      toggleInviteViaMailModal: "publicDonationPage/toggleInviteMail",
      toggleInviteViaSmsModal: "publicDonationPage/toggleInviteSms",
    }),
    donateNowHandler() {
      if (!this.payoutSetupFlag) {
        this.showToast({
          message:
            "This campaign is not accepting donations yet. Please contact the school admin.",
          color: "e",
        });
        return;
      }
      if (this.getCampaign.campaign_detail.is_accepting_payment === false) {
        this.showToast({
          message:
            "The school has completed the WizFit challenge and is no longer accepting donations. Thank you for your support!",
          color: "e",
        });
        return;
      }
      let obj = {};
      if (this.getCampaignDetail.type) {
        obj["user_type"] = this.getCampaignDetail.type.type;
        if (this.getCampaignDetail.type.type === "school") {
          obj["name"] = this.getCampaignDetail.school_detail.school_name;
          obj["id"] = this.getCampaignDetail.school_detail.id;
          obj["profile_pic"] = this.getCampaignDetail.school_detail.logo_url;
        } else if (this.getCampaignDetail.type.type === "teacher") {
          obj["name"] = this.getCampaignDetail.teacher_detail.teacher_name;
          obj["id"] = this.getCampaignDetail.teacher_detail.id;
          obj["profile_pic"] =
            this.getCampaignDetail.teacher_detail.profile_pic;
          obj["grade_title"] =
            this.getCampaignDetail.teacher_detail.grade_title;
          obj["school_name"] =
            this.getCampaignDetail.teacher_detail.campaign_school;
        } else if (this.getCampaignDetail.type.type === "student") {
          obj[
            "name"
          ] = `${this.getCampaignDetail.student_detail.student_first_name} ${this.getCampaignDetail.student_detail.student_last_name}`;
          obj["id"] = this.getCampaignDetail.student_detail.id;
          obj["profile_pic"] =
            this.getCampaignDetail.student_detail.student_profile_pic; //not present in current module
          obj["grade_teacher"] =
            this.getCampaignDetail.student_detail.grade_teacher;
          obj["grade_title"] =
            this.getCampaignDetail.student_detail.grade_title;
          obj["school_name"] =
            this.getCampaignDetail.student_detail.school_name;
        }
      } else {
        console.log(
          "getCampaignDetail: ",
          this.getCampaignDetail.campaign_detail
        );
        obj["user_type"] =
          this.getCampaignDetail.campaign_detail.sub_campaign_type;
        obj["name"] = this.getCampaignDetail.campaign_detail.organization_name;
        obj["id"] = this.getCampaignDetail.campaign_detail.organization_id;
        obj["profile_pic"] =
          this.getCampaignDetail.campaign_detail.organization_logo;
      }
     setTimeout(() => {
  this.toggleMakeDonationModal({
    show: true,
    autoSelect: _.isEmpty(obj) ? false : obj,
  });
}, 500); // delay in milliseconds (500ms = 0.5 second)

    },
    separator(numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    },
    registerStudentHandler() {
      if (this.isLoggedIn) {
        this.toggleCreateStudentModal({ show: true, actionType: "add" });
      } else {
        this.toggleParentSignUpModal({ show: true, signUpPageFlag: true });
      }
    },
    submitForm() {
      this.formLoading = true;
      const successHandler = (res) => {
        console.log(res.data);
        this.formLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.formLoading = false;
      };
      let formData = {};
      formData["invite_by"] = "student";
      formData["platform"] = "social";
      formData["campaign"] = this.getCampaignDetail.campaign_detail.id;
      formData["student"] = this.student_id;
      Axios.request_POST(
        INVITE_VIA_SOCIAL_SHARE_DETAIL,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );

      this.closeModal();
    },
    openAppleStore() {
      window.open(
        "https://apps.apple.com/us/app/harlem-wizards/id6590627343",
        "_blank"
      );
    },
    openGpayStore() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.inmotion.harlemwizard&pli=1",
        "_blank"
      );
    },
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
      userDetails: "publicDonationPage/getUserDetails",
    }),
    getCampaignDetail() {
      return this.getCampaign;
    },
    getCampaignDetailType() {
      if (
        this.isLoggedIn &&
        this.$route.name === ROUTER_URL.publicParentDashboard.name
      )
        return false;
      else if (this.getCampaign.type.type === "school") return false;
      else return this.getCampaign.type;
    },
    getDonationDetail() {
      let fund_raised = "";
      let goal = "";
      let percentage = "";
      let donors = "";
      let days_left = "";
      let img = "";
      let message = "";
      if (!this.getCampaignDetail.type) {
        fund_raised =
          this.getCampaignDetail.campaign_detail.original_total_fund_raised_50;
        goal = this.getCampaignDetail.campaign_detail.total_goal_50;
        donors = this.getCampaignDetail.campaign_detail.total_donor;
        img =
          this.getCampaignDetail.campaign_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.campaign_detail
            .original_fund_raised_status_message;
        percentage =
          this.getCampaignDetail.campaign_detail.original_fund_raise_percentage;
      } else if (this.getCampaignDetail.type.type === "school") {
        fund_raised =
          this.getCampaignDetail.school_detail.original_total_fund_raised_50;
        goal = this.getCampaignDetail.school_detail.school_goal_50;
        donors = this.getCampaignDetail.school_detail.total_donor;
        percentage =
          this.getCampaignDetail.school_detail.original_fund_raise_percentage;
        img =
          this.getCampaignDetail.school_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.school_detail
            .original_fund_raised_status_message;
      } else if (this.getCampaignDetail.type.type === "teacher") {
        fund_raised =
          this.getCampaignDetail.teacher_detail.original_class_fund_raised;
        goal = this.getCampaignDetail.teacher_detail.class_goal;
        percentage =
          this.getCampaignDetail.teacher_detail
            .original_total_class_fund_raised_percentage;
        donors = this.getCampaignDetail.teacher_detail.total_donor;
        img =
          this.getCampaignDetail.teacher_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.teacher_detail
            .original_fund_raised_status_message;
      } else if (this.getCampaignDetail.type.type === "student") {
        fund_raised = this.getCampaignDetail.student_detail.orignal_fund_raised;
        goal = this.getCampaignDetail.student_detail.student_goal;
        donors = this.getCampaignDetail.student_detail.total_donor;
        percentage =
          this.getCampaignDetail.student_detail.original_fund_raised_percentage;
        img =
          this.getCampaignDetail.student_detail
            .original_fund_raise_percentage_img;
        message =
          this.getCampaignDetail.student_detail
            .original_fund_raised_status_message;
      }
      days_left = this.getCampaignDetail.campaign_detail.remaining_day;
      // console.log(this.getCampaignDetail);
      return {
        fundRaised: fund_raised,
        Goal: goal,
        Percentage: percentage,
        Donors: donors,
        daysLeft: days_left,
        gif_img: img,
        gif_message: message,
      };
    },
    payoutSetupFlag() {
      return this.getCampaign.is_payout_done === null
        ? false
        : this.getCampaign.is_payout_done;
    },
    isLoggedIn() {
      return _.isEmpty(this.userDetails) ? false : this.userDetails;
    },
  },
  mounted() {
    // console.log(
    //   this.getCampaign.campaign_detail.is_accepting_payment,
    //   "is_accepting_payment"
    // );
    // console.log(this.getDonationDetail, "donation Detail");
    this.student_id = this.$route.query.student;
    if (!this.isLoggedIn && this.getCampaignDetail.type.type === "student") {
      this.donateNowHandler();
    }
  },
};
</script>
